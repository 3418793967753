import * as LDClient from 'launchdarkly-js-client-sdk';
import config from 'src/config';
import FeatureFlags from 'src/feature/FeatureFlags';
import currentUserService from 'src/services/CurrentUserService';
import currentTenantService from 'src/services/CurrentTenantService';

let ldClient = null;
let ldClientLoadRequest = null;

async function getLDClient() {
  if (!config?.launchDarkly?.clientSideId) {
    return null;
  }

  if (ldClient) {
    return ldClient;
  }

  if (ldClientLoadRequest) {
    return ldClientLoadRequest;
  }

  const ldUserConfig = await getLDUserConfig();

  return ldClientLoadRequest = new Promise((resolve, reject) => {
    const localLDClient = LDClient.initialize(config.launchDarkly.clientSideId, ldUserConfig, {
      bootstrap: 'localStorage',
      streaming: FeatureFlags.all().length > 0
    });

    localLDClient.on('ready', () => {
      ldClientLoadRequest = null;
      ldClient = localLDClient;
      resolve(localLDClient);
    });

    localLDClient.on('failed', cause => {
      ldClientLoadRequest = null;
      reject(cause);
    });
  });
}

async function getLDUserConfig() {
  const user = await currentUserService.get();
  const tenant = await currentTenantService.get();

  const {
    id: userId,
    firstName,
    lastName,
    email,
    username,
    phoneNumber
  } = user;

  const {
    id: tenantId,
    name: tenantName,
    sitePath: tenantSitePath,
    salesForceId: tenantSalesForceId,
    federated: tenantFederated
  } = tenant || {};

  return {
    key: `T${tenantId || 'None'}_U${userId}`,
    firstName,
    lastName,
    email,
    custom: {
      userId,
      username,
      phoneNumber,
      tenantId,
      tenantName,
      tenantSitePath,
      tenantSalesForceId,
      tenantFederated
    }
  };
}

export class FeatureFlagService {

  async initialize() {
    await getLDClient();
  }

  async get(featureFlag) {
    if (!featureFlag) {
      return featureFlag;
    }

    try {
      const ldClient = await getLDClient();

      if (!ldClient) {
        return featureFlag.defaultValue;
      }

      return ldClient.variation(featureFlag.key, featureFlag.defaultValue);
    } catch (error) {
      console.error(error);
      return featureFlag.defaultValue;
    }
  }

}

export default new FeatureFlagService();